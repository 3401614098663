<template lang="pug">
div
  b-jumbotron.text-center.mb-5
    template(slot="header") Welcome to Emmi<span v-if="globalState.isLoggedIn == true">, {{ globalState.username }}</span>
    template(slot="lead") IGDA Finland Event and Member Management Interface

  div.container(v-if="globalState.isLoggedIn == true")
    div.mb-5.text-center(v-if="globalState.userRole == 1")
      h3.mt-5.mb-5 Thanks for registering!
      div.row.justify-content-center
        p.mb-3.col-sm-10.col-md-8 Before you can work with events or memberships I need an admin to give you either "volunteer" or "board" permissions. You can ping our current admins to make this as fast as possible:
      div(v-for="admin in admins" :key="admin._id")
        strong {{admin.name}}
    div(v-if="globalState.userRole > 1")
      

  b-container.mt-4
    b-row(align-h="center")
      b-col(md).text-center.border.rounded-lg.mx-5.py-5.bg-light.border-light
        h2.mb-5 Memberships
        h5 New members:
        b-button(variant="success" to="/individual-members/register") Join IGDA Finland
        h5.mt-4 Existing members:
        b-button(variant="primary" to="/individual-members/get-link") Manage membership
      
      b-col(md).text-center.border.rounded-lg.mx-5.py-5.bg-light.border-light
        h2 Volunteer Area
        div.mt-5.pt-5(v-if="globalState.isLoggedIn == false")
          a(href="/api/auth/login")
            img(src="https://platform.slack-edge.com/img/sign_in_with_slack.png" srcset="https://platform.slack-edge.com/img/sign_in_with_slack.png 1x, https://platform.slack-edge.com/img/sign_in_with_slack@2x.png 2x")
        
        div(v-else)
          h6.text-center As {{globalState.userRole == 4 ? "an" : "a"}} {{globalState.roleName}} you can...

          div.row.justify-content-center.text-center(v-if="globalState.userRole >= 1")
            div.m-2
              b-link(to="/events")
                fa-icon.text-muted.m-3(icon="calendar-check" size="4x")
              br/
              b-button(variant="primary" to="/events") Check-in to events
            div.m-2
              b-link(to="/events/manage")
                fa-icon.text-muted.m-3(icon="calendar-alt" size="4x")
              br/
              b-button(variant="primary" to="/events/manage") Manage events
            div.m-2
              b-link(to="/events/visitors")
                fa-icon.text-muted.m-3(icon="users" size="4x")
              br/
              b-button(variant="primary" to="/events/visitors") Manage visitors
          div.row.justify-content-center.text-center(v-if="globalState.userRole >= 3")
            div.m-2
              b-link(to="/individual-members")
                fa-icon.text-muted.m-3(icon="tags" size="4x")
              br/
              b-button(variant="primary" to="/individual-members") Manage members
            div.m-2
              b-link(to="/affiliates")
                fa-icon.text-muted.m-3(icon="city" size="4x")
              br/
              b-button(variant="primary" to="/affiliates") Manage affiliates
</template>

<script>
import globalState from '@/main.js'

export default {
  data: function () {
    return {
      globalState,
      admins: []
    }
  },
  mounted: function () {
    if (this.globalState.isLoggedIn && this.globalState.userRole === 1) {
      this.axios.get('/api/roles/admins')
        .then(res => (this.admins = res.data))
        .catch(err => (this.$notify({
          group: 'error',
          type: 'error',
          title: 'Fetching admins failed',
          text: err.response.data,
          duration: -1
        })))
    }
  }
}
</script>
